import React, { FC } from 'react';
import jwt_decode from "jwt-decode";
import QRCode from "react-qr-code";
import Loading from '../../components/Loading/Loading';
import Card from '@mui/material/Card/Card';
import { CardActionArea,  CardContent, Box, CardHeader } from '@mui/material';

const LINE_URL = 'https://line.me/R/oaMessage/@275hyezc/?'

interface LineQrProps { }

const LineQr: FC<LineQrProps> = () => {
  const [lineUrl, setLineUrl] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    const token = window.location.href.split('#id_token=')[1]
    if (token) {
      const decoded: any = jwt_decode(token)
      setLineUrl(LINE_URL + decoded['oid'])
      setLoading(false)
    }
  }, []);
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100vh'}}>
      <Card sx={{ maxWidth: 400, maxHeight: 400, margin: 'auto' }}>
        <CardHeader
        avatar={
          <img className="logo" height="40" src={"/logo.svg"} alt="logo.svg" />
        }
        title="Vulcan Ceres Line Channel"
        subheader="Scan/Click the QR code to register"
        />
      <CardContent>
      <CardActionArea onClick={() => window.open(lineUrl)}>
      <QRCode
          size={256}
          style={{width: "100%" }}
          value={lineUrl}
          // viewBox={`0 0 256 256`}
        />
      </CardActionArea>
      </CardContent>
      
    </Card>
    <Loading open={loading} />
    </Box>
  )
};

export default LineQr;
