import { RasterType } from '../types/RasterTypes';

export const CERES_AUTH_COOKIE = 'CERES_AUTH_COOKIE';
export const BASE_URL = 'https://vulcan-ceres-hub.azurewebsites.net';
export const APIKEY = 'YEM7xugT2HpPh6Rfc2gdkKe82Wj8Ec';
// export const BASE_URL = 'https://8aa0-101-100-171-246.ap.ngrok.io';

export const MAP_TILER_API_KEY = 'E7GZCCoaUp3Z1NYZpoxL';

export const DAYS_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const LOGIN_DELAY = 3000;
// Open Layer Constants
export const DEFUALT_RASTER_TYPE: RasterType = 'ARCGIS';
export const SECONDARY_RASTER_TYPE: RasterType = 'OSM';
export const KML_FIT_PADDING = 200;
export const SHOW_GEOJSON_ZOOM_THRESHOLD = 14.5;
export const CLUSTER_DISTANCE_THRESHOLD = 50;
export const RISK_COLOR_MAP = {
	LOW: '#25EB21',
	MEDIUM: '#FFF85A',
	HIGH: '#FF0000',
	DEFICIENT: '#FF0000',
	OPTIMUM: '#25EB21',
};
export const HEALTH_RISK_INDEX_MAP = { LOW: 0, MEDIUM: 1, HIGH: 2 };
export const NUTRIENT_RISK_INDEX_MAP = { DEFICIENT: 2, OPTIMUM: 0 };
export const RISK_SELECTION = { HEALTH: 3, NUTRIENT: 4, LEC: 8 };
export const BLOCK_CATEGORY_NAME = 'Farm';

// CENTERS
export const HOME_LONG_LAT_CENTER = [0.0, 0.0];

// ZOOMS
export const HOME_ZOOM = 1;
export const HOME_MAX_ZOOM = 18;
export const BLOCK_REPORT_PAGE_MIN_ZOOM = 15;
export const BLOCK_REPORT_PAGE_MAX_ZOOM = 19;
export const BLOCK_REPORT_MIN_ZOOM_PAD = 2;
export const BLOCK_REPORT_MAX_ZOOM_PAD = 1;

// HOME PAGE
export const MAX_DONUT_RADIUS = 35;
export const MIN_DONUT_RADIUS = 28;
export const TOOLTIP_HEIGHT = 270;
export const TOOLTIP_WIDHT = 345;

// REPORT PAGE
export const PRODCUT_GROUP_NAME = {
	NUTRIENT: 'nutrient',
	HEALTH: 'health',
	LEC: 'LEC'
};
