import { Box, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Badge } from '@mui/material';
import React, { FC } from 'react';
import './Layout.scss';
import MoreIcon from "@mui/icons-material/MoreVert";
import { AuthService } from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { useMap } from '../map-components/MapContext/MapContext';
import { RasterType } from '../../types/RasterTypes';
import { DEFUALT_RASTER_TYPE, SECONDARY_RASTER_TYPE } from '../../constants/constants';
import { title } from 'process';

interface LayoutProps {
  children: any
  showLogout?: boolean
  title?: string
}

const Layout: FC<LayoutProps> = ({children, showLogout, title}) => {
  const navigate = useNavigate()
  const mapContext = useMap()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRasterType, setSelectedRasterType] = React.useState<RasterType>(DEFUALT_RASTER_TYPE)
  const open = Boolean(anchorEl);

  const changeTileLayer = () => {
    if(selectedRasterType === DEFUALT_RASTER_TYPE){
      mapContext.changeRasterLayer(SECONDARY_RASTER_TYPE)
      setSelectedRasterType(SECONDARY_RASTER_TYPE)
    } else {
      mapContext.changeRasterLayer(DEFUALT_RASTER_TYPE)
      setSelectedRasterType(DEFUALT_RASTER_TYPE)
    }
    handleClose()
  }
  const logout = () => {
    AuthService.signout()
    if(mapContext.map) mapContext.removeLayers(mapContext.map)
    handleClose()
    navigate("/login")
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  };
  return (
  <div className="Layout">
    <Box sx={{ flexGrow: 1 }} className="GraderAppLayout">
        <AppBar position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} enableColorOnDark style = {{background: '#000000'}}>
          <Toolbar variant="dense">
            <IconButton
            onClick={()=> {
              if(showLogout)
                navigate("/")
            }}
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <img className="logo" height="40" src={"/logo.svg"} alt="logo.svg" />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {title?title:null}
            </Typography>
            <IconButton
              aria-label="display more actions"
              edge="end"
              color="inherit"
              sx={{ ml: 2 }}
              onClick={handleClick}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={changeTileLayer}>Change Tile Layer</MenuItem>
              {showLogout?<MenuItem onClick={logout}>Logout</MenuItem>:null}
            </Menu>
          </Toolbar>
        </AppBar>
        {children}
      </Box>
  </div>
)};

export default Layout;
