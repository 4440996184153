import { Coordinate } from "ol/coordinate";
import Image from "ol/layer/Image";
import { Extent, getCenter, getHeight, getWidth } from "ol/extent";
import { Point } from "ol/geom";
import Group from "ol/layer/Group";
import {
  addCoordinateTransforms,
  addProjection,
  get,
  Projection,
  ProjectionLike,
  transform,
  transformExtent,
} from "ol/proj";
import ImageStatic, { Options } from "ol/source/ImageStatic";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";

const loadUrl = (options: any) => {
const url = options.url
const imageUrl = options.imageUrl
  var crossOrigin =
    options.crossOrigin === undefined ? "anonymous" : options.crossOrigin;

  var group = new Group();

  const addLayer = (extent: Extent, url: string, rotation: number) => {
    // function to maintain context during async img load
    var imageLayer = new Image({});
    group.getLayers().push(imageLayer);

    var imageSize: number [] = [];
    var img = document.createElement("img");
    img.onload = function () {
      imageSize[0] = img.width;
      imageSize[1] = img.height;
      imageLayer.setSource(
        source(extent, url, rotation, imageSize, options)
      );
    };
    img.crossOrigin = crossOrigin;
    if (url) img.src = url;
  }

  var last = url.lastIndexOf("/") + 1;
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.onload = function () {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(xhr.responseText, "text/xml");

    var elements = xmlDoc.getElementsByTagName("GroundOverlay");
    for (var i = 0; i < elements.length; i++) {
      var name;
      if (elements[i].getElementsByTagName("rotation").length > 0) {
        name =
          elements[i].getElementsByTagName("name")[0].childNodes[0].nodeValue;
      }
      var href
      if(imageUrl===undefined){
        href = elements[i].getElementsByTagName("href")[0].childNodes[0].nodeValue;
          if (href && href.indexOf("http:") != 0 && href.indexOf("https:") != 0) {
            href = url.slice(0, last) + href;
          }
      } else{
        href = imageUrl
      }
      var north = Number(
        elements[i].getElementsByTagName("north")[0].childNodes[0].nodeValue
      );
      var south = Number(
        elements[i].getElementsByTagName("south")[0].childNodes[0].nodeValue
      );
      var east = Number(
        elements[i].getElementsByTagName("east")[0].childNodes[0].nodeValue
      );
      var west = Number(
        elements[i].getElementsByTagName("west")[0].childNodes[0].nodeValue
      );
      var rotation = 0;
      if (elements[i].getElementsByTagName("rotation").length > 0) {
        rotation = Number(
          elements[i].getElementsByTagName("rotation")[0].childNodes[0]
            .nodeValue
        );
      }
      if(href) addLayer([west, south, east, north], href, rotation);
    }
  };
  xhr.send();
  return group;
}

const source =(
  kmlExtent: Extent, // KMLs specify the extent the unrotated image would occupy
  url: string,
  rotation: number,
  imageSize: number [],
  options: Options // attributions, crossOrigin (default to undefined)
) => {
  // calculate latitude of true scale of equidistant cylindrical projection based on pixels per degree on each axis
  proj4.defs(
    "EPSG:" + url,
    "+proj=eqc +lat_ts=0.0" +
      // (Math.acos(
      //   getHeight(kmlExtent) /
      //     imageSize[1] /
      //     (getWidth(kmlExtent) / imageSize[0])
      // ) *
      //   1) /
      //   Math.PI +
      " +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs"
  );

  if (register) {
    register(proj4);
  } // if OL5 register proj4

  // convert the extents to source projection coordinates

  var projection = get("EPSG:" + url) as ProjectionLike;
  var projExtent = transformExtent(kmlExtent, "EPSG:4326", projection);

  var angle = (-rotation * Math.PI) / 180;

  const rotateTransform = (coordinate: Coordinate) => {
    var point = new Point(coordinate);
    point.rotate(angle, getCenter(projExtent));
    return point.getCoordinates();
  }

  const normalTransform = (coordinate: Coordinate) => {
    var point = new Point(coordinate);
    point.rotate(-angle, getCenter(projExtent));
    return point.getCoordinates();
  }

  var rotatedProjection = new Projection({
    code: "EPSG:" + url + ":rotation:" + rotation,
    units: "m",
    extent: projExtent,
  });
  addProjection(rotatedProjection);

  addCoordinateTransforms(
    "EPSG:4326",
    rotatedProjection,
    (coordinate) => {
      return rotateTransform(transform(coordinate, "EPSG:4326", projection));
    },
    (coordinate) => {
      return transform(normalTransform(coordinate), projection, "EPSG:4326");
    }
  );

  addCoordinateTransforms(
    "EPSG:3857",
    rotatedProjection,
    (coordinate) => {
      return rotateTransform(transform(coordinate, "EPSG:3857", projection));
    },
    (coordinate) => {
      return transform(normalTransform(coordinate), projection, "EPSG:3857");
    }
  );

  return new ImageStatic({
    projection: rotatedProjection,
    url: url,
    imageExtent: projExtent,
    attributions: options.attributions,
    crossOrigin: options.crossOrigin,
  });
}

export const kmlOverlay = {
    loadUrl: loadUrl,
    source: source,
  }
