import { Card, CardActionArea, CardMedia, CssBaseline, List, Stack, SwipeableDrawer, SxProps, Theme, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadImageGallery, setJobImage } from "../../../redux/actions";

import "./ImageGallery.scss";
import RiskLegend from "../../RiskLegend/RiskLegend";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { log } from "../../../services/loggerService";
import moment from "moment";
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import { useMap } from "../../map-components/MapContext/MapContext";
import { useSnack } from "../../SnackContext/SnackContext";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
interface ImageGalleryProps {}

const ImageGallery: FC<ImageGalleryProps> = () => {
  const [open, setOpen] = useState(false);

  let dispatch = useDispatch();
  const mapContext = useMap();
  const snackContext = useSnack();
  const { blockId } = useParams();
  const product = useAppSelector((state) => state.data.product);
  const imageGallery = useAppSelector((state) => state.data.imageGallery);
  const jobImage = useAppSelector((state) => state.data.jobImage)

  useEffect(() => {
    if (blockId && product.product_id) {
      dispatch<any>(loadImageGallery(blockId, product.product_id));
    }
  }, [blockId, product]);

  useEffect(() => {
    if (imageGallery && imageGallery.length > 0) {
      dispatchJobImage(imageGallery[0]);
    } else if (mapContext && imageGallery) {
      mapContext.removeKMLOverlay();
      snackContext.displaySnackBar("This selection has no Snapshot image", "error");
    }
  }, [imageGallery])

  const dispatchJobImage = (jobImage: any) => {
    dispatch<any>(setJobImage(jobImage));
    setOpen(false)
  }

  const Style = {
    height: "96px",
    maxWidth: "120px",
  };

  const borderStyle: SxProps<Theme> = {
    border: "4px solid",
    color: (theme) => theme.palette.primary.main,
    borderRadius: "1em"
  }

  const cardStyle = {
    borderRadius: "1em",
  }

  const gallery = () => {
    return (
      <Card className="card">
        <CssBaseline />
        <Typography variant="h6">Image Gallery</Typography>
        <Stack direction={"row"} sx={{ alignItems: 'center', paddingX: '1em', overflowX:'auto' }} spacing={1}>
          {imageGallery?.map((item: any) => {
            return (
              <>
              <Card elevation={5} key={item.job_block_id} sx={{...(jobImage?.job_block_id === item.job_block_id ? borderStyle : cardStyle), flexShrink:0}}>
                <CardActionArea sx={{ paddingLeft: "1em", paddingRight: "1em" }} onClick={() => dispatchJobImage(item)}>
                  <CardMedia
                    style={Style}
                    className="card-media"
                    component="img"
                    src={item.image_url}
                    alt="No image found"
                    sx={{ padding: "0.5em 0.5em 0 0.5em", objectFit: "fill" }}
                  />
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {moment(item.image_date).utc().format("MMMM Do YYYY")}
                  </Typography>
                </CardActionArea>
              </Card>
              </>
            );
          })}
        </Stack>
      </Card>
    );
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  
  log("*******ImageGallery********")
  return (
    <>
      <RiskLegend selectedRiskVarient={product.product_group_id} />
      <div className="ImageGallery">
        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CardActionArea onClick={() => {
            setOpen(true);
          }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: "50px",
                width: "200px",
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {imageGallery?.slice(0, 4)?.map((item: any) => (
                  <CardMedia
                    component="img"
                    key={item.job_block_id}
                    style={{ width: "30px", height: "30px", margin: '5px' }}
                    image={item.image_url}
                    alt={item.job_block_id}
                  />
                ))}
              </Box>


              <KeyboardControlKeyIcon style={{ marginRight: '5px' }} />

            </Box>
          </CardActionArea>
        </Card>

        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          className="drawer"
        >
          {gallery()}
        </SwipeableDrawer>
      </div>
    </>
  );
};

export default ImageGallery;