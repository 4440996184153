import { Box, Card, Typography } from "@mui/material";
import React, { FC } from "react";
import { RISK_SELECTION } from "../../constants/constants";
import "./RiskLegend.scss";

interface RiskLegendProps {
  selectedRiskVarient: number;
}

const RiskLegend: FC<RiskLegendProps> = ({ selectedRiskVarient }) => (
  <div className="RiskLegend">
    {selectedRiskVarient === RISK_SELECTION.HEALTH ? (
      <Card
        sx={{
          height: "70px",
          width: "200px",
        }}
      >
        <Typography color="text.secondary" align="center">Overall Health Risk</Typography>
        <Box
          sx={{ display: "flex", marginLeft: "0.7rem", alignItems: "center" }}
        >
          <Typography
            color="text.secondary"
            style={{
              fontSize: "small",
            }}
          >
            High
          </Typography>
          &nbsp;
          <img
            style={{
              width: "120px",
              height: "30px",
              borderRadius: "2%",
            }}
            src={require("../../resources/high_low_range.png")}
          ></img>
          &nbsp;
          <Typography
            color="text.secondary"
            style={{
              fontSize: "small",
            }}
          >
            Low
          </Typography>
          &nbsp;
        </Box>
      </Card>
    ) : selectedRiskVarient === RISK_SELECTION.NUTRIENT ? (
      <Card
        sx={{
          height: "70px",
          width: "200px",
        }}
      >
        <Typography color="text.secondary" align="center">Nitrogen Level</Typography>
        <Box
          sx={{ display: "flex", marginLeft: "0.7rem", alignItems: "center" }}
        >
          <Typography
            color="text.secondary"
            style={{
              fontSize: "small",
            }}
          >
            Deficient
          </Typography>
          &nbsp;
          <img
            style={{
              width: "120px",
              height: "30px",
              borderRadius: "2%",
            }}
            src={require("../../resources/high_low_range.png")}
          ></img>
          &nbsp;
          <Typography
            color="text.secondary"
            style={{
              fontSize: "small",
            }}
          >
            Optimum
          </Typography>
          &nbsp;
        </Box>
      </Card>
    ) : <Card
      sx={{
        height: "70px",
        width: "200px",
      }}
    >
      <Typography color="text.secondary" align="center">LEC Risk</Typography>
      <Box
        sx={{ display: "flex", marginLeft: "0.7rem", alignItems: "center" }}
      >
        <Typography
          color="text.secondary"
          style={{
            fontSize: "small",
          }}
        >
          High
        </Typography>
        &nbsp;
        <img
          style={{
            width: "120px",
            height: "30px",
            borderRadius: "2%",
          }}
          src={require("../../resources/high_low_range.png")}
        ></img>
        &nbsp;
        <Typography
          color="text.secondary"
          style={{
            fontSize: "small",
          }}
        >
          Low
        </Typography>
        &nbsp;
      </Box>
    </Card>}
  </div>
);

export default RiskLegend;
