import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/authService";
import Layout from "../Layout/Layout";
import "./PrivateRoute.scss";

interface PrivateRouteProps {
  children: any;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  return AuthService.isAuthenticated() ? (
    <Layout showLogout={true} title={'Vulcan Ceres'}>{children}</Layout>
  ) : (
    <Navigate to={`/login?return-url=${window.location.pathname}`} />
  );
};

export default PrivateRoute;
