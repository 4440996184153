import React, { FC } from "react";
import "./SearchBar.scss";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
interface SearchBarProps {
  keyVal: number;
  features: any[];
  onChange: any;
  startButtonOnClick?: () => void;
}

const SearchBar: FC<SearchBarProps> = ({
  keyVal,
  features,
  onChange,
  startButtonOnClick,
}) => {
  return (
    <div className={"SearchBar"}>
      {startButtonOnClick ? (
        <IconButton
          sx={{ margin: "auto", marginRight: "4px" }}
          size="medium"
          color="default"
          aria-label="add"
          onClick={startButtonOnClick}
        >
          <ArrowBackIosIcon />
        </IconButton>
      ) : null}

      <Autocomplete
        key={keyVal}
        id="block-selector"
        sx={{ width: 300 }}
        options={features.sort(
          (a, b) => {
            if (b.properties){return -b.properties.estate.localeCompare(a.properties.estate)}
             else {
              return -b.estate_name.localeCompare(a.estate_name)}}
        )}
        groupBy={(option) => option.properties?option.properties.estate:option.estate_name}
        autoHighlight
        getOptionLabel={(option) =>
          option.properties?`${option.properties.complex} ${option.properties.block_name}`:`${option.complex_name} ${option.block_name}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.properties?`${option.properties.complex} ${option.properties.block_name}`:`${option.complex_name} ${option.block_name}`}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            sx={{ backgroundColor: "rgba(0,0,0,0.5)", borderRadius: "4px" }}
            {...params}
            variant="outlined"
            placeholder="Choose a Farm"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
