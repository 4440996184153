import { Grid } from "@mui/material";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";

import "./FarmInsights.scss";
import { PRODCUT_GROUP_NAME } from "../../../constants/constants";
import { useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { log } from "../../../services/loggerService";
import FarmOverallInsights from "./FarmOverallInsights";

interface FarmInsightsModalProps {
}

const FarmInsightsModal: FC<FarmInsightsModalProps> = () => {
    const products: any[] = useAppSelector((state) => state.data.products);
    const { blockId } = useParams();

    log("*******FarmModalInsights********")
    return (
        <>
            <Grid container spacing={2} style={{}}>
                {
                    products.map((p) => {
                        return (
                            <>
                                {
                                    p.product_group_name == PRODCUT_GROUP_NAME.NUTRIENT ? null :
                                        <Grid item xs={4} key={p.product_id}>
                                            <FarmOverallInsights product={p} blockId={blockId} />
                                        </Grid>
                                }
                            </>

                        )
                    })
                }
            </Grid >

        </>
    );
};

export default FarmInsightsModal;
