import { Typography } from "@mui/material";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import React, { FC, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { loadFarmInsights } from "../../../redux/actions";

import "./FarmInsights.scss";
import { PRODCUT_GROUP_NAME } from "../../../constants/constants";
import { useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { log } from "../../../services/loggerService";

interface FarmInsightsProps {
}

const FarmInsights: FC<FarmInsightsProps> = () => {
  const { blockId } = useParams();
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({});

  let dispatch = useDispatch();
  const farmInsights = useAppSelector((state) => state.data.farmInsights);
  let product = useAppSelector((state) => state.data.product);

  useEffect(() => {
    if (blockId && product.product_id) {
      dispatch<any>(loadFarmInsights(blockId, product.product_id));
    }
  }, [blockId, product]);

  useEffect(() => {
    const imageDate = farmInsights.map((item: any) =>
      moment(item.image_date).utc().format("MM/YYYY")
    );
    const meanValue = farmInsights?.map((item: any) => item.mean_value);
    const xAxis: ApexOptions = {
      theme: {
        mode: 'dark'
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 1,
        row: {
          colors: ['transparent'], // takes an array which will be repeated on columns
          opacity: 0.01
        },
      },
      chart: {
        background: 'transparent',
        id: "farm-yield",
        toolbar: {
          tools: {
            pan: false,
            download: true,
            zoom: false,
            reset: false,
            selection: false
          }
        }
      },
      colors: ['#008577'],
      xaxis: {
        categories: imageDate,
      },
      yaxis: {
        min: product.min_value,
        max: product.max_value,
        tickAmount: 7,
        decimalsInFloat: 3
      }
    };

    const yAxis = [
      {
        name: "Mean Value",
        data: meanValue,
      },
    ];
    setChartSeries(yAxis);
    setChartOptions(xAxis);
  }, [farmInsights]);
  log("*******FarmInsights********")
  return (
    <>
      {(product.product_group_name == PRODCUT_GROUP_NAME.NUTRIENT || product.product_group_name == PRODCUT_GROUP_NAME.LEC) ? null : (
        <div className="FarmInsights">
          <Typography
            style={{ color: "#BDBDBD", marginLeft: "8%", fontSize: "medium" }}
            variant="h6"
          >
            {product.product_name ? product.product_name.toLocaleUpperCase() : ""} Trends
          </Typography>
          <br></br>
          <ReactApexChart
            type="line"
            series={chartSeries}
            options={chartOptions}
            height={340}
            width={350}
          />
        </div>
      )}
    </>
  );
};

export default FarmInsights;
