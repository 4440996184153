import "./RoundTextField.scss";
import { IconButton, Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

export const RoundTextField = ({
  label,
  type = "text",
  value,
  onChange,
}: {
  label: string;
  type: string;
  value: string;
  onChange: any;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleTogglePassword = () =>
    setShowPassword((showPassword) => !showPassword);
  return (
    <>
      {type === "password" && (
        <>
          <Input
            className="rounded-text-box"
            type={showPassword ? "text" : "password"}
            sx={{
              padding: "1.2rem",
            }}
            placeholder={label}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{color:"grey"}}
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            onChange={onChange}
            value={value}
          />
        </>
      )}
      {type !== "password" && (
        <Input
          type={type}
          sx={{
            padding: "1.2rem",
          }}
          disableUnderline={true}
          className="rounded-text-box"
          placeholder={label}
          onChange={onChange}
          value={value}
        />
      )}
    </>
  );
};
