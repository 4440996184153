import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { SnackContextModel } from "./SnackContext.model";

const SnackContext = React.createContext<SnackContextModel | undefined>(undefined);

type SnackContextProviderProps = { children: React.ReactNode };

export const SnackContextProvider: React.FC<SnackContextProviderProps> = ({
  children,
}) => {
  const [showSnackBar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>("success");

  const displaySnackBar = (message: string, severity: AlertColor) => {
    setSnackBarMessage(message);
    setSnackBarSeverity(severity);
    setShowSnackbar(true);
  };

  return (
    <SnackContext.Provider
      value={{
        displaySnackBar: displaySnackBar
      }}
    >
      <Snackbar

        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ top: { xs: 70, sm: 70 } }}
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >

        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
          variant="filled"
        >

          {snackBarMessage}

        </Alert>

      </Snackbar>
      {children}
    </SnackContext.Provider>
  );
};

export const useSnack = () => {
  const context = React.useContext(SnackContext);
  if (context === undefined) {
    throw new Error("context must be used in a context provider");
  }
  return context;
};
