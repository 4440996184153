import * as types from "./actionType";

const initialState = {
  products: [],
  cropInfo: {},
  farmInsights: [],
  imageGallery: undefined,
  loading: true,
  allBlocks: [],
  blockInfo: {},
  product: {},
  jobImage: {}
};

const productReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case types.GET_CROP_INFO:
      return {
        ...state,
        cropInfo: action.payload,
        loading: false,
      };
    case types.GET_FARM_INSIGHTS:
      return {
        ...state,
        farmInsights: action.payload,
        loading: false,
      };
    case types.GET_FARM_INSIGHTS:
      return {
        ...state,
        farmInsights: action.payload,
        loading: false,
      };
    case types.GET_IMAGE_GALLERY:
      return {
        ...state,
        imageGallery: action.payload,
        loading: false,
      };
    case types.GET_BLOCK_INFO:
      return {
        ...state,
        blockInfo: action.payload
      }
    case types.GET_ALL_BLOCKS:
      return {
        ...state,
        allBlocks: action.payload
      }
    case types.GET_SELECTED_PRODUCT:
      return {
        ...state,
        product: action.payload
      }
    case types.GET_JOB_IMAGE:
      return {
        ...state,
        jobImage: action.payload
      }
    case types.DESTROY_STATE:
      return{
        ...state,
        products: [],
        cropInfo: {},
        farmInsights: [],
        imageGallery: undefined,
        loading: true,
        allBlocks: [],
        blockInfo: {},
        product: {},
        jobImage: {}
      }
    default:
      return state;
  }
};



export default productReducers;
