import { Box, Card, CardContent, CardMedia, List, Stack, Typography } from "@mui/material";
import {
  Divider,
  ListItem,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@mui/material/Avatar";
import {
  faCarrot,
  faChartArea,

} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import "./FarmInfo.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { memo, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { loadBlockInfo, loadCropInfo } from "../../../redux/actions";
import { log } from "../../../services/loggerService";
import { faHeartbeat, faMoneyBill, faLeaf } from "@fortawesome/free-solid-svg-icons";
import { PRODCUT_GROUP_NAME } from "../../../constants/constants";

const FarmInfo = () => {
  const { blockId } = useParams();
  const dispatch = useAppDispatch()
  const blockInfo = useAppSelector((state) => state.data.blockInfo, shallowEqual);
  const jobImage = useAppSelector((state) => state.data.jobImage, shallowEqual);
  const product = useAppSelector((state) => state.data.product, shallowEqual);
  const cropInfo = useAppSelector((state) => state.data.cropInfo, shallowEqual);

  useEffect(() => {
    if (blockId && jobImage.job_id) {
      dispatch<any>(loadBlockInfo(blockId, jobImage.job_id));
    }
  }, [blockId, jobImage]);

  useEffect(() => {
    if (blockInfo.job_block_id && product.product_group_id) {
      dispatch<any>(loadCropInfo(blockInfo.job_block_id, product.product_group_id));
    }
  }, [blockInfo, product]);
  log(`*******FarmInfo********`)

  return (
    <Card sx={{ width: "100%" }} >
      {/* <CardMedia
        component="img"
        height="140"
        image={require("../../../resources/palm_farm.jpg")}
        alt="Palm Farm"
      /> */}
      <CardContent>
        <Stack direction={'row'} justifyContent="space-between">
          <Typography
            className="farm-name"
            style={{ fontWeight: "bolder" }}
            variant="h6"
            color="text.secondary"
          >
            {blockInfo.block_name}
          </Typography>
          <Typography
            className="farm-name"
            style={{ fontWeight: "bolder" }}
            variant="h6"
            color="text.secondary"
          >
            {moment(jobImage.image_date).utc().format("MMMM Do YYYY")}
          </Typography>
        </Stack>
        <hr></hr>
        <ListItem component="div">
          <ListItemAvatar>
            <Avatar>
              <FontAwesomeIcon icon={faCarrot} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Crop Type "
            secondary={blockInfo.crop_type ? blockInfo.crop_type : "-"}
          />
        </ListItem>
        <Divider variant="inset" component="div" />
        {/* <Stack direction={"row"} > */}

        {/* <Box sx={{ width: "50%" }}> */}
        <Stack direction={"row"}>
          {/* <Avatar>
                <FontAwesomeIcon icon={faHeartbeat} />
              </Avatar> */}

          {/* <Stack direction={"column"} alignItems={"center"}>
                  <Typography variant="subtitle1" color="text.secondary">Health Risk</Typography>
                  <>{cropInfo.risk ? cropInfo.risk.toUpperCase() : "-"}</>
                </Stack> */}
          <List>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faHeartbeat} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Health Risk" secondary={(cropInfo.risk ? cropInfo.risk.toUpperCase() : "-")} />
            </ListItem >
          </List>
          <List>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faMoneyBill} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Affected (%)" secondary={(cropInfo.affected_area_precent ? cropInfo.affected_area_precent.toFixed(2) : "-") + " %"} />
            </ListItem >
          </List>

        </Stack>
        {/* </Box> */}
        {/* <Box sx={{ width: "50%" }}>
            <Stack direction={"row"} spacing={1}>
              <Avatar>
                <FontAwesomeIcon icon={faHeartbeat} />
              </Avatar>
              <>
                <Stack direction={"column"} alignItems={"center"}>
                  <Typography variant="subtitle1" color="text.secondary">Affected(%)</Typography>
                  <>{(cropInfo.affected_area_precent ? cropInfo.affected_area_precent.toFixed(2) : "-") + " %"}</>
                </Stack>
              </>
            </Stack>
          </Box> */}

        {/* <List>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faMoneyBill} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Percentage Affected" secondary={(cropInfo.affected_area_precent ? cropInfo.affected_area_precent.toFixed(2) : "-") + " %"} />
            </ListItem>
          </List> */}

        {/* </Stack> */}
        {/* <List
          sx={{
            width: "100%",
            maxWidth: 360,
            // bgcolor: "background.paper",
          }}
        >
          {product.product_group_name == PRODCUT_GROUP_NAME.NUTRIENT ? (
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faLeaf} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Nitrogen Level" secondary={(cropInfo.risk ? cropInfo.risk.toUpperCase() : "")} />
            </ListItem>
          ) : (
            <><ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faHeartbeat} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Overall Health Risk" secondary={(cropInfo.risk ? cropInfo.risk.toUpperCase() : "-")} />
            </ListItem ><ListItem component="div">
                <ListItemAvatar>
                  <Avatar>
                    <FontAwesomeIcon icon={faMoneyBill} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Percentage Affected" secondary={(cropInfo.affected_area_precent ? cropInfo.affected_area_precent.toFixed(2) : "-") + " %"} />
              </ListItem></>
          )
          }

        </List> */}
        {/* <ListItem component="div">
          <ListItemAvatar>
            <Avatar>
              <FontAwesomeIcon icon={faChartArea} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Total Area"
            secondary={(blockInfo.area ? blockInfo.area.toFixed(2) : "-") + " ha"}
          />
        </ListItem> */}
        <Divider variant="inset" component="div" />
      </CardContent>
    </Card>

  );
};

export default memo(FarmInfo);
