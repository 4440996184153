import { Typography } from "@mui/material";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import React, { FC, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import "./FarmInsights.scss";
import { PRODCUT_GROUP_NAME } from "../../../constants/constants";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";

interface FarmInsightsProps {
    product: any,
    blockId: any
}

const FarmOverallInsights: FC<FarmInsightsProps> = (props) => {
    const [chartSeries, setChartSeries] = useState<any[]>([]);
    const [chartOptions, setChartOptions] = useState<ApexOptions>({});
    const [farmInsights, setFarmInsights] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    //const farmInsights = useAppSelector((state) => state.data.farmInsights);
    //   let product = useAppSelector((state) => state.data.product);

    useEffect(() => {
        if (props.blockId && props.product.product_id) {
            setIsLoading(true);
            axios
                .get(`/report/blockPeriodicInsightsByProductId?block_id=${props.blockId}&product_id=${props.product.product_id}`)
                .then((res: any) => {
                    setFarmInsights(res.data);
                    setIsLoading(false)
                })
                .catch((error: any) => {
                    setIsLoading(false)
                    if (error && error.response && error.response.data) console.log(error.response.data)
                    else console.log(error);
                });
        }
    }, [props]);

    useEffect(() => {
        if (farmInsights) {
            console.log(farmInsights)
            const imageDate = farmInsights.map((item: any) =>
                moment(item.image_date).utc().format("MM/YYYY")
            );
            const meanValue = farmInsights?.map((item: any) => item.mean_value);
            const xAxis: ApexOptions = {
                theme: {
                    mode: 'dark'
                },
                grid: {
                    show: true,
                    borderColor: '#90A4AE',
                    strokeDashArray: 1,
                    row: {
                        colors: ['transparent'], // takes an array which will be repeated on columns
                        opacity: 0.01
                    },
                },
                chart: {
                    background: 'transparent',
                    id: "farm-yield",
                    toolbar: {
                        tools: {
                            pan: false,
                            download: true,
                            zoom: false,
                            reset: false,
                            selection: false
                        }
                    }
                },
                colors: ['#008577'],
                xaxis: {
                    categories: imageDate,
                },
                yaxis: {
                    min: props.product.min_value,
                    max: props.product.max_value,
                    tickAmount: 7,
                    decimalsInFloat: 3
                }
            };

            const yAxis = [
                {
                    name: "Mean Value",
                    data: meanValue,
                },
            ];
            setChartSeries(yAxis);
            setChartOptions(xAxis);
        }
    }, [farmInsights]);
    return (
        <>
            <Loading open={isLoading} />
            {props.product.product_group_name == PRODCUT_GROUP_NAME.NUTRIENT ? null : (
                <div className="FarmInsights">
                    <Typography
                        style={{ color: "#BDBDBD", marginLeft: "8%", fontSize: "medium" }}
                        variant="h6"
                    >
                        {props.product.product_name ? props.product.product_name.toLocaleUpperCase() : ""} Trends
                    </Typography>
                    <br></br>
                    <ReactApexChart
                        type="line"
                        series={chartSeries}
                        options={chartOptions}
                        height={340}
                        width={350}
                    />
                </div>
            )}
        </>
    );
};

export default FarmOverallInsights;
