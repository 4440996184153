import { Backdrop, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import './Loading.scss';

interface LoadingProps {
  open: boolean;
}

const Loading: FC<LoadingProps> = ({ open }) => (
  <div className="Loading">
   <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </div>
);

export default Loading;
