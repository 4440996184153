import React, { Suspense } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';
import { themeOptions } from './styles/CustomThemeOptions';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import Loading from './components/Loading/Loading';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Report from './pages/Report/Report';
import { MapContextProvider } from './components/map-components/MapContext/MapContext';
import Map from './components/map-components/Map/Map';
import Home from './pages/Home/Home';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import {
	configAuthHeaders,
	configBaseUrl,
	configRefreshToken,
} from './services/axiosConfigService';
import { DAYS_IN_MILLISECONDS } from './constants/constants';
import { SnackContextProvider } from './components/SnackContext/SnackContext';

import Alert from './pages/Alert/Alert';
import Layout from './components/Layout/Layout';
import LineQr from './pages/LineQR/LineQR';
import MobileReport from './pages/MobileReport/MobileReport';
import MobileAlert from './pages/MobileAlert/MobileAlert';

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				cacheTime: DAYS_IN_MILLISECONDS * 2, // 2 days
			},
		},
	});
	const persister = createSyncStoragePersister({
		storage: window.localStorage,
	});
	configBaseUrl();
	configAuthHeaders();
	configRefreshToken();
	return (
		<div className="App">
			<ThemeProvider theme={createTheme(themeOptions)}>
				<CssBaseline />
				<Suspense fallback={<Loading open={true} />}>
					<PersistQueryClientProvider
						client={queryClient}
						persistOptions={{ persister }}
					>
						<MapContextProvider>
							<SnackContextProvider>
								<BrowserRouter>
									<Routes>
										<Route
											path="/login"
											element={
												<Map>
													<Login />
												</Map>
											}
										></Route>
										<Route
											path="/report/:blockId"
											element={
												<Map>
													<PrivateRoute>
														<Report />
													</PrivateRoute>
												</Map>
											}
										></Route>
										<Route path="/lineQR" element={<LineQr />}></Route>
										<Route
											path="*"
											element={
												<Map>
													<PrivateRoute>
														<Home />
													</PrivateRoute>
												</Map>
											}
										></Route>
										<Route
											path="/alert/:jobBlockId/:alertTypeId/:userId"
											element={
												<Map>
													<Layout showLogout={false} title={'Alert'}>
														<Alert />
													</Layout>
												</Map>
											}
										></Route>
										<Route
											path="/mobileReport/:blockId/:jobBlockId/:productId/:jobId"
											element={
												<Map isMobile={true}>
													<MobileReport />
												</Map>
											}
										></Route>
										<Route
											path="/mobileAlert/:jobBlockId/:alertTypeId/:userId"
											element={
												<Map isMobile={true}>
													<MobileAlert />
												</Map>
											}
										></Route>
									</Routes>
								</BrowserRouter>
							</SnackContextProvider>
						</MapContextProvider>
					</PersistQueryClientProvider>
				</Suspense>
			</ThemeProvider>
		</div>
	);
}

export default App;
