import styled from "@emotion/styled";
import {
  Card,
  CardActionArea,
  CardMedia,
  Collapse,
  IconButton,
  IconButtonProps,
  Box,
  Typography
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./FarmMaps.scss";
import axios from "axios";
import { useMap } from "../../map-components/MapContext/MapContext";
import { useAppSelector } from "../../../redux/hooks";
import { log } from "../../../services/loggerService";
import { useSnack } from "../../SnackContext/SnackContext";

interface FarmMapsProps { }

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Style: React.CSSProperties = {
  height: "32px",
  maxWidth: "120px",
  // border: "1px solid white",
};

const collapsableCardStyle: React.CSSProperties = {
  marginBottom: '5px'
}

const Names = ["Snapshot", "Emerging Stress", "Raw", "Super Resolution", "Base Map"];
const Images = [
  require("../../../resources/sat_image_1.jpg"),
  require("../../../resources/sat_image_2.jpg"),
  require("../../../resources/raw.jpg"),
  require("../../../resources/super-res.jpg"),
  require("../../../resources/Base-map.jpg")
];


const FarmMaps: FC<FarmMapsProps> = () => {
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    // transition: theme.transitions.create('transform', {
    //   duration: theme.transitions.duration.shortest,
    // }),
  }));

  const SUPER_RES = "2a8dc9a6-04b4-4141-a8f9-8072d65ab26a";
  const RAW = "b3b1211e-b90c-493d-b08a-41844bb1fa2d";
  const CHANGE_DETECTION = "592c3bb4-2899-472c-aa53-98dd9147ae04";

  const [selectedName, setSelectedName] = useState<string>(Names[0]);
  const [selectedImage, setSelectedImage] = useState<any>(Images[0]);

  const [kmlFile, setKmlFile] = useState<any>();
  const [kmlImage, setKmlImage] = useState<any>();
  const mapContext = useMap();
  const snackContext = useSnack();
  const blockInfo = useAppSelector((state: any) => state.data.blockInfo);
  const jobImage = useAppSelector((state: any) => state.data.jobImage);
  const product = useAppSelector((state: any) => state.data.product);

  useEffect(() => {
    if (blockInfo.job_block_id) {
      axios
        .get(`/report/blockKml?job_block_id=${blockInfo.job_block_id}`)
        .then((res) => {
          if (kmlFile) {
            const kml_first_part = kmlFile.split("?")[0]
            const res_data_first_part = res.data.split("?")[0]
            if (kml_first_part !== res_data_first_part) {
              setKmlFile(res.data);
            }
          } else {
            setKmlFile(res.data);
          }

        })
        .catch((error) => {
          console.log(error?.response?.data);
          setKmlFile("");
          handleAlert(selectedName);
        });
    }
  }, [blockInfo]);

  useEffect(() => {
    if (jobImage.job_block_id === blockInfo.job_block_id) {
      setSelectedImage(Images[0]);
      setSelectedName(Names[0]);
      if (jobImage.image_url === "") {
        handleAlert(Names[0]);
      }
      setKmlImage(jobImage.image_url);
    } else {
      setKmlImage("");
    }
  }, [jobImage, blockInfo])

  useEffect(() => {
    if (mapContext.map) {
      if (kmlFile && kmlImage) {
        mapContext.changeKMLOverlay(kmlFile, kmlImage, mapContext.map);
      }
    }

  }, [kmlFile, kmlImage, mapContext.map]);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleImageTypeClick = (
    productId: string,
    name: string,
    image: any
  ) => {
    setSelectedImage(image);
    setSelectedName(name);
    handleExpandClick();
    if (name !== "Base Map") {
      axios.get(`/report/blockKmlImage?job_block_id=${blockInfo.job_block_id}&product_id=${productId}`)
        .then((image) => {
          if (image.data === "") {
            handleAlert(name);
          }
          setKmlImage(image.data);
        }).catch((imageError) => {
          handleAlert(name);
          console.log(imageError?.response.data);
          setKmlImage("");
        })
    } else {
      if (mapContext.map) {
        mapContext.removeKMLOverlay()
      }
    }
  };

  const handleAlert = (name: string) => {
    snackContext.displaySnackBar(`This selection has no ${name} Image`, 'error')
  };

  log("*******FarmMaps********");
  return (
    <div className="FarmMaps">
      <Box>
        <Card style={collapsableCardStyle}>
          <Collapse in={true} timeout="auto">
            <Card>
              <CardActionArea onClick={() => handleExpandClick()}>
                <CardMedia
                  style={Style}
                  className="card-media"
                  component="img"
                  image={selectedImage}
                  alt="No image found"
                />
                <Typography
                  align="center"
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {selectedName}
                </Typography>
              </CardActionArea>
            </Card>
          </Collapse>
        </Card>

        <Card
          style={{
            visibility: expanded ? "visible" : "hidden",
            backgroundColor: "rgba(255, 0, 0, 0)",
          }}
        >
          <Collapse in={expanded} timeout="auto">
            {selectedName !== Names[0] ? <Card style={collapsableCardStyle} >
              <CardActionArea
                onClick={() =>
                  handleImageTypeClick(product?.product_id, Names[0], Images[0])
                }
              >
                <CardMedia
                  style={Style}
                  className="card-media"
                  component="img"
                  image={Images[0]}
                  alt="No image found"
                />
                <Typography
                  align="center"
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {Names[0]}
                </Typography>
              </CardActionArea>
            </Card> : null}

            {selectedName !== Names[1] ? <Card style={collapsableCardStyle} >
              <CardActionArea
                onClick={() =>
                  handleImageTypeClick(CHANGE_DETECTION, Names[1], Images[1])
                }
              >
                <CardMedia
                  style={Style}
                  className="card-media"
                  component="img"
                  image={Images[1]}
                  alt="Paella dish"
                />
                <Typography
                  align="center"
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {Names[1]}
                </Typography>
              </CardActionArea>
            </Card> : null}

            {selectedName !== Names[2] ? <Card style={collapsableCardStyle} >
              <CardActionArea
                onClick={() => handleImageTypeClick(RAW, Names[2], Images[2])}
              >
                <CardMedia
                  style={Style}
                  className="card-media"
                  component="img"
                  image={Images[2]}
                  alt="Paella dish"
                />
                <Typography
                  align="center"
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {Names[2]}
                </Typography>
              </CardActionArea>
            </Card> : null}

            {selectedName !== Names[3] ? <Card style={collapsableCardStyle} >
              <CardActionArea
                onClick={() =>
                  handleImageTypeClick(SUPER_RES, Names[3], Images[3])
                }
              >
                <CardMedia
                  style={Style}
                  className="card-media"
                  component="img"
                  image={Images[3]}
                  alt="Paella dish"
                />
                <Typography
                  align="center"
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {Names[3]}
                </Typography>
              </CardActionArea>
            </Card> : null}
            {selectedName !== Names[4] ?
              <Card style={collapsableCardStyle}>
                <CardActionArea
                  onClick={() =>
                    handleImageTypeClick("", Names[4], Images[4])
                  }
                >
                  <CardMedia
                    style={Style}
                    className="card-media"
                    component="img"
                    image={Images[4]}
                    alt="Paella dish"
                  />
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {Names[4]}
                  </Typography>
                </CardActionArea>
              </Card> : null}

          </Collapse>
        </Card>
      </Box>
    </div>
  );
};

export default FarmMaps;
