import Stroke from "ol/style/Stroke";
import Chart from "ol-ext/style/Chart";


export const createDonutChart = (data, radius, colors) => {
    let stroke
    if (data.length===3){
        if (data[0]===0 && data[1]===0){
            stroke = colors[2]
        } else if (data[0]===0 && data[2]===0){
            stroke = colors[1]
        } else if (data[1]===0 && data[2]===0){
            stroke = colors[0]
        } else {
            stroke = "#fff"
        }
    } 
    if (data.length===1){
        stroke = colors[0]
    }
    
    // console.log(radius)
    return  new Chart({
        type: "donut",
        radius: radius,
        data: data,
        colors: colors,
        stroke: new Stroke({
          color: stroke,
          width: 0
        })
      })
}