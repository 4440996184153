import { Feature, Map, View } from "ol";
import { CollectionEvent } from "ol/Collection";
import { EventsKey } from "ol/events";
import { createEmpty, extend } from "ol/extent";
import { Geometry } from "ol/geom";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, transformExtent } from "ol/proj";
import { OSM, XYZ } from "ol/source";
import React, { useEffect, useState } from "react";
import {
  BLOCK_REPORT_MAX_ZOOM_PAD,
  DEFUALT_RASTER_TYPE,
  HOME_LONG_LAT_CENTER,
  HOME_MAX_ZOOM,
  HOME_ZOOM,
  KML_FIT_PADDING,
  MAP_TILER_API_KEY,
} from "../../../constants/constants";
import { kmlOverlay } from "../../../services/kmlService";
import { RasterType } from "../../../types/RasterTypes";
import { MapContextModel } from "./MapContext.model";

const MapContext = React.createContext<MapContextModel | undefined>(undefined);

type MapContextProviderProps = { children: React.ReactNode };

export const MapContextProvider: React.FC<MapContextProviderProps> = ({
  children,
}) => {
  const key = MAP_TILER_API_KEY;
  const [clusterMap, setClusterMap] = useState<Map>();
  const [kmlGroup, setKmlGroup] = useState<LayerGroup>();
  const [activeKML, setActiveKML] = useState<boolean>(false);
  const [rasterLayer, setRasterLayer] = useState<TileLayer<XYZ | OSM>>();
  const [eventListenerKeys, setEventListenerKeys] = useState<EventsKey []>([])
  const doLayerOperation = (
    map: Map,
    operation: (layer: BaseLayer) => void
  ) => {
    const LayersToOperate: BaseLayer[] = [];
    map?.getLayers().forEach((layer, i) => {
      if (i > 0) {
        LayersToOperate.push(layer);
      }
    });
    LayersToOperate.forEach((layer) => {
      operation(layer);
    });
  };

  const removeLayers = (map: Map, layersToSkip: string[] = []) => {
    doLayerOperation(map, (layer) => {
      if (
        layer.get("name") !== undefined &&
        !layersToSkip.includes(layer.get("name"))
      )
        map.removeLayer(layer);
    });
  };

  const fitExtentAroundFeatures = (
    map: Map | undefined,
    features: Feature<Geometry>[],
    duration: number = 500,
    padding: number[] = [200, 200, 200, 200]
  ) => {
    const extent = createEmpty();
    const blockNames: string [] = []
    features.forEach((feature: any) => {
      if (features.length < 100) blockNames.push(feature.getProperties().blockName)
      extend(extent, feature.getGeometry().getExtent());
    });
    if (features.length < 100) console.log(blockNames.join(','))
    const view = map?.getView()!!;
    view.fit(extent, {
      duration: duration,
      padding: padding,
    });
  };

  const changeRasterLayer = (r_type: RasterType) => {
    let tempRaster: TileLayer<XYZ | OSM>;
    switch (r_type) {
      case "GOOGLE":
        tempRaster = new TileLayer({
          source: new XYZ({
            url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
            maxZoom: HOME_MAX_ZOOM,
          }),
        });

        break;
        case "ARCGIS":
          tempRaster = new TileLayer({
            source: new XYZ({
              url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              maxZoom: HOME_MAX_ZOOM,
            }),
          });
  
          break;
      case "MAPTILER":
        tempRaster = new TileLayer({
          source: new XYZ({
            url:
              "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=" +
              key,
            maxZoom: HOME_MAX_ZOOM,
          }),
        });

        break;
      default:
        tempRaster = new TileLayer({
          source: new OSM({
            maxZoom: HOME_MAX_ZOOM,
          }),
        });
    }
    tempRaster.set("name", "raster-layer");
    if (clusterMap && rasterLayer) {
      clusterMap.removeLayer(rasterLayer);
      clusterMap.getLayers().insertAt(0, tempRaster);
    }
    setRasterLayer(tempRaster);
    return tempRaster;
  };

  const changeKMLOverlay = (url: string, imageUrl: string, map: Map) => {
    const extent = createEmpty();
    const currentZoom = map.getView().getZoom()
    console.log("CURRENT ZOOM -> "+imageUrl, currentZoom)
    if(currentZoom){
      map.getView().setMinZoom(HOME_ZOOM);
    }
    const group = kmlOverlay.loadUrl({
      url: url,
      imageUrl: imageUrl,
    });
    group.set("name", "kml-overlay");
    group.getLayers().once("add", (evt: CollectionEvent) => {
      console.log("kml-overlay", "add")
      evt.element.once("change:source", () => {
        if (evt.element.getSource && evt.element.getSource().getProjection) {
          console.log("kml-overlay", "change:source")

          var imageProj = evt.element.getSource().getProjection();
          extend(
            extent,
            transformExtent(
              imageProj.getExtent(),
              imageProj,
              map.getView().getProjection()
            )
          );
          map.getView().fit(
            // left bottom, right, top
            extent
            .map((val, index) => {
              if (index === 0 || index === 1) {
                val -= KML_FIT_PADDING;
              } else val += KML_FIT_PADDING;
              return val;
            } )
          );

          const currentZoom = map.getView().getZoom()
          console.log("CURRENT ZOOM -> "+ currentZoom)
          if(currentZoom){
            map.getView().setMinZoom(currentZoom-BLOCK_REPORT_MAX_ZOOM_PAD);
          }
         
        }
      });
    });
    group.setOpacity(1);
    if (!activeKML) {
      map.addLayer(group);
    } else {
      map.removeLayer(kmlGroup!!);
      map.addLayer(group);
    }
    setActiveKML(true);
    setKmlGroup(group);
  };

  const removeKMLOverlay = () => {
    if (clusterMap && kmlGroup) {
      clusterMap.removeLayer(kmlGroup);
      setActiveKML(false)
    }
  };

  useEffect(() => {
    const raster = changeRasterLayer(DEFUALT_RASTER_TYPE);
    const map = new Map({
      layers: [raster],
      view: new View({
        center: fromLonLat(HOME_LONG_LAT_CENTER),
        zoom: HOME_ZOOM,
        maxZoom: HOME_MAX_ZOOM,
      }),
    });
    setClusterMap(map);
  }, []);
  return (
    <MapContext.Provider
      value={{
        map: clusterMap,
        eventListenerKeys: eventListenerKeys,
        setEventListenerKeys: setEventListenerKeys,
        changeRasterLayer: changeRasterLayer,
        changeKMLOverlay: changeKMLOverlay,
        removeKMLOverlay: removeKMLOverlay,
        removeLayers: removeLayers,
        fitExtentAroundFeatures: fitExtentAroundFeatures,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => {
  const context = React.useContext(MapContext);
  if (context === undefined) {
    throw new Error("context must be used in a context provider");
  }
  return context;
};
