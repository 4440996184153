import * as React from "react";
import "./Products.scss";
import { useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { loadProducts, setSelectedProduct } from "../../../redux/actions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../redux/hooks";
import { log } from "../../../services/loggerService";
import { unByKey } from "ol/Observable";
import { useMap } from "../../map-components/MapContext/MapContext";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Products = React.memo(() => {
  const [value, setValue] = React.useState(0);
  const [productValue, setProductValue] = React.useState("");
  let dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeDropdown = (event: SelectChangeEvent) => {
    setProductValue(event.target.value);
    let productObj = products.filter((value) => value.product_id == event.target.value);
    // console.log(productObj)
    dispatch<any>(setSelectedProduct(productObj[0]));
  };

  const handleClick = (product: any) => {
    dispatch<any>(setSelectedProduct(product));
  };

  const products: any[] = useAppSelector((state) => state.data.products, shallowEqual);
  const product = useAppSelector((state) => state.data.product, shallowEqual);

  useEffect(() => {
    dispatch<any>(loadProducts());
  }, []);

  let mapContext = useMap();
  useEffect(() => {

    if (mapContext.map) {
      mapContext.removeLayers(mapContext.map, []);
      unByKey(mapContext.eventListenerKeys)
    }

    if (products.length > 0) {
      setValue(0)
      dispatch<any>(setSelectedProduct(products[0]));
      setProductValue(products[0].product_id)
    }
  }, [products]);

  log("*******Products********")
  return (
    <>
      <Box sx={{ maxWidth: { sm: 350 } }}>
        <FormControl sx={{ m: 1, minWidth: 330 }}>
          <InputLabel id="demo-simple-select-helper-label">PRODUCT</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={productValue}
            label="PRODUCT"
            onChange={handleChangeDropdown}
            sx={{ textAlign: "center" }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {products.map((item: any) => {
              return <MenuItem sx={{ justifyContent: "center" }} key={item.product_id} value={item.product_id}>{item.product_name.replaceAll("_", " ").toUpperCase()}</MenuItem>
            })}
          </Select>
        </FormControl>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {products.map((item: any) => {
            return (
              <Tab key={`${item.product_id}_${item.product_id}`} label={item.product_name.replaceAll("_", " ")} onClick={() => handleClick(item)} />
            )
          })}
        </Tabs> */}
      </Box>
    </>
  );
});
export default Products;
