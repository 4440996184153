import { Stack } from "@mui/material";
import { SetStateAction, useState } from "react";
import { RoundedButton } from "../../components/roundedButton/RoundedButton";
import { RoundTextField } from "../../components/roundtextField/RoundTextField";
import { AuthService } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import CeresLogo from "../../components/CeresLogo/CeresLogo";
import Link from "@mui/material/Link";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="Login">
      <div className="login-container">
      <CeresLogo />
        <div className="login-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              AuthService.authenticate(
                userName,
                password,
                () => {
                  navigate("/");
                },
                () => setError(true)
              ).finally(() => setLoading(false));
            }}
          >
            <Stack direction="column" spacing={1}>
              <RoundTextField
                value={userName}
                onChange={(e: {
                  target: { value: SetStateAction<string> };
                }) => {
                  setUserName(e.target.value);
                }}
                label="Username"
                type="text"
              ></RoundTextField>
              <RoundTextField
                label="Password"
                type="password"
                value={password}
                onChange={(e: {
                  target: { value: SetStateAction<string> };
                }) => {
                  setPassword(e.target.value);
                }}
              ></RoundTextField>
              <RoundedButton
                loading={loading}
                type="submit"
                label="Login"
              ></RoundedButton>
              {error && (
                <p className="error-msg">* Invalid username or password</p>
              )}
            </Stack>
            <Stack direction="row">
              <Link className="forgot-password"  href="https://vulcanaib2c.b2clogin.com/vulcanaib2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ceres_sign_up_only&client_id=e97ab0ec-70c6-42da-9e91-437c2a735020&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fceresapp.vulcan-ai.com%2F&scope=openid&response_type=id_token&prompt=login" >
                Don't Have Account?SignUp
              </Link>
              <Link className="forgot-password" style={{ marginLeft: "20px" }} href= "https://vulcanaib2c.b2clogin.com/vulcanaib2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ceres_reset_password&client_id=ef1fa17b-58ff-43b0-948c-9e6e5919d306&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fceresapp.vulcan-ai.com&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=a0UbF0LXkdse6G-mopKxPs5Rzp724qrafHhGqzDQ12o">
                Forgot password
              </Link>
            </Stack>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
