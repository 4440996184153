import axios from "axios";
import {
  BASE_URL,
  CERES_AUTH_COOKIE,
  LOGIN_DELAY,
} from "../constants/constants";
import { timeout } from "../utils/Utils";
import { AuthService } from "./authService";

export const configBaseUrl = () => {
  axios.defaults.baseURL = BASE_URL;
};

export const configAuthHeaders = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = JSON.parse(localStorage.getItem(CERES_AUTH_COOKIE) ?? "{}");
      if (token && config?.headers) {
        config.headers["Authorization"] = `Bearer ${token.access_token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const configRefreshToken = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { config } = error;
      const originalRequest = config;
      if (
        error.response &&
        error.response.status !== 498 &&
        error.response.status !== 403 &&
        error.response.status !== 401
      ) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (
        ((error.response && error.response.status === 498) ||
          (error.response && error.response.status === 403) ||
          (error.response && error.response.status === 401)) &&
        !originalRequest._retry &&
        originalRequest.url !== "/auth/refresh"
      ) {
        console.log("Refreshing token");
        console.log(originalRequest.url);
        originalRequest._retry = true;
        const token =
          localStorage.getItem(CERES_AUTH_COOKIE) &&
          JSON.parse(localStorage.getItem(CERES_AUTH_COOKIE) ?? "{}");
        const refresh_token = token && token.refresh_token;
        const payload = {
          refresh_token: refresh_token,
        };
        return axios
          .post(`/auth/refresh`, payload)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(CERES_AUTH_COOKIE, JSON.stringify(res.data));

              originalRequest.headers.Authorization =
                "Bearer " + res.data.access_token;
              timeout(LOGIN_DELAY).then(() => {
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(axios(originalRequest));
                  }, 3000);
                });
              });
            } else {
              // AuthService.signout();
              window.location.href = "/login";
              return Promise.reject(error);
            }
          })
          .catch((err) => {
            // AuthService.signout();
            window.location.href = "/login";
            return Promise.reject(error);
          });
      } else {
        // AuthService.signout();
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
  );
};
