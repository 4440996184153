import React, { FC, useEffect, useRef } from "react";
import { useMap } from "../MapContext/MapContext";
import "./Map.scss";
import "ol/ol.css";

interface MapProps {
  children: React.ReactNode,
  isMobile?: boolean
}

const Map: FC<MapProps> = ({children, isMobile}) => {

  let mapRef = useRef<any | undefined>();
  const mapContext = useMap()
  useEffect(()=> {
    mapContext.map?.setTarget(mapRef.current)
  }, [mapContext.map])

  return (
    <>
    <div
    className={isMobile?"":"Map"}
    ref={mapRef}
    style={{ position: "absolute", width: "100%", height: "100vh", overflow: "hidden", zIndex: -1 }}
  ></div>
    {children}
    </>
  
  )
};

export default Map;
