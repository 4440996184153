import { configureStore } from '@reduxjs/toolkit';
import productReducers from './reducers';


const store = configureStore({
    reducer: {
        data: productReducers,
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store