import React, { FC, useEffect, useState } from 'react';
import './MobileAlert.scss';
import axios from 'axios';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill } from 'ol/style';
import { useParams } from 'react-router-dom';
import { useMap } from '../../components/map-components/MapContext/MapContext';

interface MobileAlertProps {}

const MobileAlert: FC<MobileAlertProps> = () => {
  const [kmlFile, setKmlFile] = useState<any>();
    const [image, setImage] = useState<any>();
    const [alertGeoList, setAlertGeoList] = useState<any>([]);
    const [isKmlSet, setIsKmlSet] = useState<boolean>(false)
    const mapContext = useMap();
    const { jobBlockId, alertTypeId } = useParams();

    useEffect(() => {
        if (mapContext.map) {
            const onMapClick = mapContext.map?.on("click", (event) => {
                console.log("event.pixel ->", event.pixel);
                // event.map.getLayers()
                event.map.getLayers().forEach((layer: any) => {
                    console.log(layer.get("name"))
                    if (layer &&
                        layer.get("name") !== undefined &&
                        layer.get("name") === "alert-polygon-layer") {
                        layer.getLayers().forEach((vectorLayer: VectorLayer<VectorSource<Geometry>>) => {
                            vectorLayer.getFeatures(event.pixel).then((features) => {
                                if (features.length > 0) {
                                    console.log('features test on click', features[0].getProperties())
                                    let properties = features[0].getProperties();
                                    showOnClick("https://maps.google.com?q=" + properties.latitude + "," + properties.longitude);
                                }
                            });
                        });
                    }
                });
            });
            mapContext.setEventListenerKeys([onMapClick])
        }
    }, [mapContext.map]);

    useEffect(() => {
        if (jobBlockId && alertTypeId) {
            fetchKmlFile(jobBlockId)
            fetchImage(jobBlockId, alertTypeId);
            fetchAlertGeoJson(jobBlockId, alertTypeId);
        }
    }, [jobBlockId, alertTypeId]);

    useEffect(() => {
        if (kmlFile && image) {
            if (mapContext.map) {
                if (kmlFile && image) {
                    mapContext.changeKMLOverlay(kmlFile, image, mapContext.map);
                    setIsKmlSet(true)
                }
            }
        }
    }, [kmlFile, image])

    useEffect(() => {
        if (alertGeoList.length > 0 && isKmlSet) {
            const polygonGroup = new LayerGroup();
            polygonGroup.set("name", "alert-polygon-layer");
            mapContext.map?.addLayer(polygonGroup)
            // map?.getLayers().insertAt(3, polygonGroup);
            var geoJsonFormat = new GeoJSON({
                featureProjection: "EPSG:3857",
            });
            for (let geoJson of alertGeoList) {
                var features = geoJsonFormat.readFeatures(
                    JSON.stringify(geoJson)
                );
                const ploygonVectorSource = new VectorSource({
                    attributions: "GeoJsonLayer",
                    features: features,
                });

                const blockPolygonLayer = new VectorLayer({
                    source: ploygonVectorSource,
                    style: new Style({
                        stroke: new Stroke({
                            color: `#fff`,
                            width: 1,
                        }),
                        fill: new Fill({
                            color: `blue`,
                        }),
                    }),
                });

                blockPolygonLayer.set("name", geoJson.features[0].properties.alert_id);
                blockPolygonLayer.setVisible(true);
                polygonGroup?.getLayers().insertAt(0, blockPolygonLayer);
            }
        }
    }, [alertGeoList, isKmlSet, mapContext.map])

    const showOnClick = (url: any) => {
        window.open(url);
    };

    const fetchImage = (jbId: string, alertId: string) => {
        axios.get(`/alert/image?jobBlockId=${jbId}&alertTypeId=${alertId}`).then((image) => {
            setImage(image.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchKmlFile = (jbId: string) => {
        axios
            .get(`/alert/kmlFile?jobBlockId=${jbId}`)
            .then((res) => {
                setKmlFile(res.data);
            })
            .catch((error) => {
                console.log(error?.response?.data);
            });
    }

    const fetchAlertGeoJson = (jbId: string, alertId: string) => {
        axios.get(`/alert/alertList?jobBlockId=${jbId}&alertTypeId=${alertId}`).then((geoJson) => {
            setAlertGeoList(geoJson.data);
        }).catch((error) => {
            console.log(error);
        })
    }



    return null
};

export default MobileAlert;
