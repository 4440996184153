import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMap } from '../../components/map-components/MapContext/MapContext';
import { APIKEY } from '../../constants/constants';

const MobileReport = () => {
	const [kmlFile, setKmlFile] = useState<any>();
	const [kmlImage, setKmlImage] = useState<any>();
	const mapContext = useMap();
	// 199DA498-B29A-4224-B8C0-3C6B620EE066/86AD2B44-81BD-4405-8883-1D977BD8A921/592C3BB4-2899-472C-AA53-98DD9147AE04
	const { blockId, jobBlockId, productId, jobId } = useParams();

	useEffect(() => {
		if (jobId && blockId && productId) {
			axios
				.get(
					`/mobile/blockImageUrlByProductIdJobIdBlockId/${APIKEY}?block_id=${blockId}&product_id=${productId}&job_id=${jobId}`
				)
				.then((res) => {
					if (res.data && res.data.image_url) {
						setKmlImage(res.data.image_url);
					}
				})
				.catch((error) => {
					console.log(error?.response?.data);
				});
		}
	}, [blockId, productId, jobId]);

	useEffect(() => {
		if (jobBlockId) {
			axios
				.get(`/mobile/blockKml/${APIKEY}?job_block_id=${jobBlockId}`)
				.then((res) => {
					setKmlFile(res.data);
				})
				.catch((error) => {
					console.log(error?.response?.data);
					setKmlFile('');
				});
		}
	}, [jobBlockId]);

	useEffect(() => {
		if (mapContext.map) {
			if (kmlFile && kmlImage) {
				mapContext.changeKMLOverlay(kmlFile, kmlImage, mapContext.map);
			}
		}
	}, [kmlFile, kmlImage, mapContext.map]);
	return null;
};

export default MobileReport;
