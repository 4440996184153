export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_CROP_INFO = "GET_CROP_INFO"
export const GET_FARM_INSIGHTS = "GET_FARM_INSIGHTS"
export const GET_IMAGE_GALLERY = "GET_IMAGE_GALLERY"
export const GET_BLOCK_INFO = "GET_BLOCK_INFO"
export const GET_ALL_BLOCKS = "GET_ALL_BLOCKS"
export const GET_SELECTED_PRODUCT = "GET_SELECTED_PRODUCT"
export const GET_JOB_IMAGE = "GET_JOB_IMAGE"
export const DESTROY_STATE = "DESTROY_STATE"

