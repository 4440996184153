import axios from "axios";
import { CERES_AUTH_COOKIE, LOGIN_DELAY } from "../constants/constants";
import { timeout } from "../utils/Utils";




export const AuthService = {
  isAuthenticated: () => {
    return !!localStorage.getItem(CERES_AUTH_COOKIE);
  },
  authenticate: async (
    username: any,
    password: any,
    successCb: () => void,
    errorCallback: () => void
  ) => {
    try {
      const loginResult = await axios.post(`/auth/login`, {
        username: username,
        password: password,
      });
      if (loginResult.data && loginResult.data.access_token) {
        localStorage.setItem(
          CERES_AUTH_COOKIE,
          JSON.stringify(loginResult.data)
        );
        await timeout(LOGIN_DELAY)
        successCb();
      } 
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },
  tokenAuthenticate: async (
    token: any,
    successCb: () => void,
    errorCallback: () => void
  ) => {
    try {
      const loginResult = await axios.post(`/auth/login`, {
        access_token: token,
      });
      if (loginResult.data && loginResult.data.access_token) {
        localStorage.setItem(CERES_AUTH_COOKIE, loginResult.data);
        successCb();
      } 
    } catch (error) {
      console.error(error);
     
    }
  },
  signout() {
    localStorage.removeItem(CERES_AUTH_COOKIE);
  },
};
