import React, { useEffect, useState } from "react";
import { useMap } from "../../components/map-components/MapContext/MapContext";
import "./Alert.scss";
import { useParams } from "react-router-dom";

import axios from "axios";
import { Fab } from "@mui/material";
import SmsIcon from '@mui/icons-material/Sms';
import LayerGroup from "ol/layer/Group";
import { Map } from "ol";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style } from "ol/style";
import { Geometry } from "ol/geom";

const Alert = () => {

    const [kmlFile, setKmlFile] = useState<any>();
    const [image, setImage] = useState<any>();
    const [alertData, setAlertData] = useState<any>({});
    const [alertGeoList, setAlertGeoList] = useState<any>([]);
    const [isKmlSet, setIsKmlSet] = useState<boolean>(false)
    const [lineURL, setLineURL] = useState<any>();
    const [isOpen, setIsOpen] = useState(true);
    const mapContext = useMap();
    const { jobBlockId, alertTypeId } = useParams();
    let map: Map | undefined = mapContext.map;
    let line_message_url = 'https://line.me/R/oaMessage/';

    useEffect(() => {
        if (map) {
            const onMapClick = map?.on("click", (event) => {
                console.log("event.pixel ->", event.pixel);
                // event.map.getLayers()
                event.map.getLayers().forEach((layer: any) => {
                    console.log(layer.get("name"))
                    if (layer &&
                        layer.get("name") !== undefined &&
                        layer.get("name") === "alert-polygon-layer") {
                        layer.getLayers().forEach((vectorLayer: VectorLayer<VectorSource<Geometry>>) => {
                            vectorLayer.getFeatures(event.pixel).then((features) => {
                                if (features.length > 0) {
                                    console.log('features test on click', features[0].getProperties())
                                    let properties = features[0].getProperties();
                                    showOnClick("https://maps.google.com?q=" + properties.latitude + "," + properties.longitude);
                                }
                            });
                        });
                    }
                });
            });
            mapContext.setEventListenerKeys([onMapClick])
        }
    }, [map]);

    useEffect(() => {
        if (jobBlockId && alertTypeId) {
            fetchKmlFile(jobBlockId)
            fetchImage(jobBlockId, alertTypeId);
            fetchAlertData(jobBlockId, alertTypeId);
            fetchAlertGeoJson(jobBlockId, alertTypeId);
        }
    }, [jobBlockId, alertTypeId]);

    useEffect(() => {
        if (kmlFile && image) {
            if (mapContext.map) {
                if (kmlFile && image) {
                    mapContext.changeKMLOverlay(kmlFile, image, mapContext.map);
                    setIsKmlSet(true)
                }
            }
        }
    }, [kmlFile, image])

    useEffect(() => {
        if (alertGeoList.length > 0 && isKmlSet) {
            const polygonGroup = new LayerGroup();
            polygonGroup.set("name", "alert-polygon-layer");
            map?.addLayer(polygonGroup)
            // map?.getLayers().insertAt(3, polygonGroup);
            var geoJsonFormat = new GeoJSON({
                featureProjection: "EPSG:3857",
            });
            for (let geoJson of alertGeoList) {
                var features = geoJsonFormat.readFeatures(
                    JSON.stringify(geoJson)
                );
                const ploygonVectorSource = new VectorSource({
                    attributions: "GeoJsonLayer",
                    features: features,
                });

                const blockPolygonLayer = new VectorLayer({
                    source: ploygonVectorSource,
                    style: new Style({
                        stroke: new Stroke({
                            color: `#fff`,
                            width: 1,
                        }),
                        fill: new Fill({
                            color: `blue`,
                        }),
                    }),
                });

                blockPolygonLayer.set("name", geoJson.features[0].properties.alert_id);
                blockPolygonLayer.setVisible(true);
                polygonGroup?.getLayers().insertAt(0, blockPolygonLayer);
            }
        }
    }, [alertGeoList, isKmlSet])

    const showOnClick = (url: any) => {
        window.open(url);
    };

    const fetchImage = (jbId: string, alertId: string) => {
        axios.get(`/alert/image?jobBlockId=${jbId}&alertTypeId=${alertId}`).then((image) => {
            setImage(image.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchKmlFile = (jbId: string) => {
        axios
            .get(`/alert/kmlFile?jobBlockId=${jbId}`)
            .then((res) => {
                setKmlFile(res.data);
            })
            .catch((error) => {
                console.log(error?.response?.data);
            });
    }

    const fetchAlertData = (jbId: string, alertId: string) => {
        axios.get(`/alert/alertData?jobBlockId=${jbId}&alertTypeId=${alertId}`).then((alert) => {
            setAlertData(alert.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchAlertGeoJson = (jbId: string, alertId: string) => {
        axios.get(`/alert/alertList?jobBlockId=${jbId}&alertTypeId=${alertId}`).then((geoJson) => {
            setAlertGeoList(geoJson.data);
        }).catch((error) => {
            console.log(error);
        })
    }



    return (
        <div className="Alert">
            <div className={isOpen ? "show card" : "hide card"}>
                <button className="toggle" onClick={() => setIsOpen(!isOpen)}>
                    <div className="toggleLabel"></div>
                </button>
                <div className="details">
                    <Fab className="line" style={{ backgroundColor: "green" }} onClick={() => {
                        showOnClick(line_message_url + alertData.agro_line_id + `/?` + `Hi Agronomist, I have queries regaring the block ${alertData.block_name} kindily assist on this. Link: ${window.location.href}`);
                    }}>
                        <SmsIcon style={{ color: "white" }}></SmsIcon>
                    </Fab>
                    {/* <p id="date">{fakeAlertProps.date}</p>
                <p id="fieldName">{fakeAlertProps.fieldName}</p> */}
                    {alertData.job_date && alertData.job_date.split("T").length>0?<p><span id="jobDate">{alertData.job_date.split("T")[0]}</span></p>:null}
                    <p><span>Block Id: </span><span id="alertId">{alertData.block_id}</span></p>
                    <p><span>Block Name: </span><span id="alertId">{alertData.block_name}</span></p>
                    <p><span id="issueType">{alertData.alert_title}</span></p>
                    {/* <p><span>Location: </span><span id="location">{alertData.lat} {alertData[0].long}</span></p> */}
                    <p id="comment">{alertData.description}</p>
                </div>
            </div>
        </div>
    );
}

export default Alert;