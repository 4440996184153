import * as types from "./actionType"
import axios from 'axios';
import { log } from "../services/loggerService";


const getProducts = (products11:any) => ({
    type: types.GET_PRODUCTS,
	payload:products11,
})

const getBlockInfo = (block: any) => ({
  type: types.GET_BLOCK_INFO,
	payload:block,
})

const getCropInfo = (cropInfo:any) => ({
    type: types.GET_CROP_INFO,
	payload:cropInfo,
})

const getFarmInsights = (farmInsights:any) => ({
    type: types.GET_FARM_INSIGHTS,
	payload:farmInsights,
})


const getImageGallery = (imageGallery:any) => ({
  type: types.GET_IMAGE_GALLERY,
payload:imageGallery,
})

const selectedProduct = (product: any) => ({
  type:types.GET_SELECTED_PRODUCT,
  payload:product
})

const getAllBlocks = (data: any) => ({
  type: types.GET_ALL_BLOCKS,
  payload: data
})

const getJobImage = (jobImage: any) => ({
  type: types.GET_JOB_IMAGE,
  payload: jobImage
})

export const loadBlockInfo = (blockId: string, jobId: string) => {
  log("--------loadBlockInfo------------")
  return function (dispatch:any) {
    axios
      .get(`/report/blockInfo?block_id=${blockId}&job_id=${jobId}`)
      .then((res) => {
        dispatch(getBlockInfo(res.data));
      })
      .catch((error) => {
        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
        dispatch(getBlockInfo({}))

      });
  };
}

export const loadProducts = () => {
  log("--------loadProducts------------")
  return function (dispatch: any) {
    axios
      .get(`/report/productsByUser`)
      .then((res) => {
        dispatch(getProducts(res.data));
        
      })
      .catch((error) => {
        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
        dispatch(getProducts([]))
      });
  };
}
export const setSelectedProduct = (productSelected: any) => {
  log("--------setSelectedProduct------------")
  return function (dispatch: any) {
    dispatch(selectedProduct(productSelected));
    }
}


export const loadCropInfo = (job_block_id: any,product_group_id: any) => {
  log("--------loadCropInfo------------")
	return function (dispatch:any) {
    axios
      .get(`/report/productGroupInsightsByJobBlockId?job_block_id=${job_block_id}&product_group_id=${product_group_id}`)
      .then((res) => {
        dispatch(getCropInfo(res.data));
      })
      .catch((error) => {
        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
        dispatch(getCropInfo({}))

      });
  };
}

export const loadFarmInsights =  (block_id: any,product_id: any) => {
  log("--------loadFarmInsights------------")
 return function (dispatch:any) {
    axios 
      .get(`/report/blockPeriodicInsightsByProductId?block_id=${block_id}&product_id=${product_id}`)
      .then((res) => {
        dispatch(getFarmInsights(res.data));
      })
      .catch((error) => {
        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
        dispatch(getFarmInsights([]))

      });
  };
}


export const loadImageGallery = (block_id: any,product_id: any) => {
  log("--------loadImageGallery------------")
	return function (dispatch:any) {
    axios
      .get(`/report/blockImageGalleryByProductId?block_id=${block_id}&product_id=${product_id}`)
      .then((res) => {
        dispatch(getImageGallery(res.data));
      })
      .catch((error) => {

        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
        dispatch(getImageGallery([]))

      });
  };
}

export const loadAllBlocks = () => {
  log("--------loadAllBlocks------------")
	return function (dispatch:any) {
    axios
      .get(`/farm/getAllFarms`)
      .then((res) => {
        dispatch(getAllBlocks(res.data));
      })
      .catch((error) => {
        dispatch(getAllBlocks([]));
        if(error && error.response && error.response.data) console.log(error.response.data) 
        else console.log(error);
      });
  };
}

export const setJobImage = (imageData: any) => {
  log("--------setJobImage------------")
  return function (dispatch: any) {
    dispatch(getJobImage(imageData));
    }
}
