import { Typography } from "@mui/material";
import { ReactComponent as VulcanLogo } from "./../../images/Ceres.svg";
import "./CeresLogo.scss";

const CeresLogo = () => {
  return (
    <div className="CeresLogo">
      <VulcanLogo className="logo" />
      <Typography gutterBottom variant="h4" className="logo-text">

      </Typography>
    </div>
  );
};

export default CeresLogo;