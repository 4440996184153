import {
  Box,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  styled,
  Theme,
  Stack,
  Modal,
  Fade,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import React, { FC, useState } from "react";
import "./Report.scss";
import FarmInfo from "../../components/Report/FarmInfo/FarmInfo";
import Products from "../../components/Report/Products/Products";
import CropInfo from "../../components/Report/CropInfo/CropInfo";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReportSearch from "../../components/Report/ReportSearch/ReportSearch.lazy";
import FarmInsights from "../../components/Report/FarmInsights/FarmInsights";
import ImageGallery from "../../components/Report/ImageGallery/ImageGallery";
import FarmMaps from "../../components/Report/FarmMaps/FarmMaps";
import { log } from "../../services/loggerService";
import FarmInsightsModal from "../../components/Report/FarmInsights/FarmInsightsModal";
import CloseIcon from '@mui/icons-material/Close';
// import Modal from '@mui/joy/Modal';
// import Sheet from '@mui/joy/Sheet';
// import ModalClose from '@mui/joy/ModalClose';
// import Typography from '@mui/joy/Typography';

interface ReportProps { }
const drawerWidth = 405;

const Report: FC<ReportProps> = React.memo(() => {
  const [anchorState, setAnchorState] = useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(0)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(0)} + 1px)`,
    },
  });

  const Sidebar = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    borderRadius: '20px'

  };

  const toggleDrawer = () => {
    setAnchorState(!anchorState);
  };

  log("*******REPORT********")
  return (
    <div className="Report">
      <Sidebar variant="permanent" open={anchorState}>
        <Box
          // sx={{ width: 250 }}
          role="presentation"
        >
          <Stack direction={"row"}>
            <List>
              <ListItem>
                <Box sx={{ height: '40px' }} />
              </ListItem>
              <ListItem>
                <ReportSearch />
              </ListItem>
              <Divider />

              <ListItem>
                <FarmInfo />
              </ListItem>
              <Divider />
              <ListItem>
                <Products />
              </ListItem>
              <Divider />
              {/* <ListItem>
                <CropInfo />
              </ListItem>
              <Divider /> */}
              <ListItem>
                <FarmInsights />
              </ListItem>
            </List>
          </Stack>
        </Box>
      </Sidebar>

      <div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          sx={{ borderRadius: "20px" }}
        >
          <Fade in={open}>

            <Stack direction={'column'}>
              <div style={{
                cursor: 'pointer',
                width: '40px',
                position: 'absolute',
                top: '7%',
                right: '8%'
              }} onClick={handleClose}>
                <Avatar>
                  <CloseIcon />
                </Avatar>
              </div>
              <Box sx={style}>
                <FarmInsightsModal />
              </Box>
            </Stack>

          </Fade>
        </Modal>
      </div>
      <DrawerHeader
        className={anchorState ? "side-toggle" : "side-toggle-closed"}
      >
        <IconButton sx={{ margin: '1px' }} onClick={() => toggleDrawer()}>
          {anchorState ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </IconButton>
      </DrawerHeader>
      <DrawerHeader
        className={anchorState ? "side-toggle-2" : ""}
      >
        <IconButton sx={{ margin: '1px' }} onClick={() => handleOpen()}>
          {anchorState ? <TimelineIcon /> : <TimelineIcon />}
        </IconButton>
      </DrawerHeader>
      <FarmMaps />
      <ImageGallery />
    </div>
  );
});

export default Report;
