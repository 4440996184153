import { ThemeOptions } from "@mui/material/styles/createTheme";

export const themeOptions: ThemeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#008577',
        dark: '#00574b',
      },
      secondary: {
        main: '#F5F5DC',
      },
    },
  };
  