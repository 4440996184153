import React, { useEffect, useRef, useState } from "react";
import { Feature, Map, MapBrowserEvent } from "ol";
import { fromLonLat } from "ol/proj";
import { Cluster, Vector as VectorSource } from "ol/source";
import { useNavigate } from "react-router-dom";
import "ol/ol.css";

import "./Home.scss";
import { Chip } from "@mui/material";
import VectorLayer from "ol/layer/Vector";
import GeoJson from "ol/format/GeoJSON";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import { useMap } from "../../components/map-components/MapContext/MapContext";
import {
  BLOCK_CATEGORY_NAME,
  CLUSTER_DISTANCE_THRESHOLD,
  RISK_COLOR_MAP,
  HEALTH_RISK_INDEX_MAP,
  HOME_LONG_LAT_CENTER,
  HOME_MAX_ZOOM,
  HOME_ZOOM,
  MAX_DONUT_RADIUS,
  MIN_DONUT_RADIUS,
  NUTRIENT_RISK_INDEX_MAP,
  RISK_SELECTION,
  SHOW_GEOJSON_ZOOM_THRESHOLD,
  TOOLTIP_HEIGHT,
  TOOLTIP_WIDHT,
} from "../../constants/constants";
import { createDonutChart } from "../../services/olExtensionService";
import SearchBar from "../../components/SearchBar/SearchBar";
import FarmInfoTooltip from "../../components/FarmInfoTooltip/FarmInfoTooltip";
import axios from "axios";
import GeoJSON from "ol/format/GeoJSON";
import LayerGroup from "ol/layer/Group";
import Geometry from "ol/geom/Geometry";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../components/Loading/Loading";
import RiskLegend from "../../components/RiskLegend/RiskLegend";
import { unByKey } from 'ol/Observable';
import { useDispatch } from "react-redux";
import * as types from "../../redux/actionType";

const Home = () => {
  const geoJsonBlockIdSet = new Set<string>();
  const [selectedRiskVarient, setSelectedRiskVarient] = useState<number>(
    RISK_SELECTION.HEALTH
  );
  const [searchFeatures, setSearchFeatures] = useState<any[]>([]);

  const mapContext = useMap();
  let map: Map | undefined = mapContext.map;
  const popupRef = useRef<any | undefined>();
  const popupNameRef = useRef<any | undefined>();
  const popupInfoRef = useRef<any | undefined>();
  const popupLocationRef = useRef<any | undefined>();
  const navigate = useNavigate();
  const innerCircleFill = new Fill({
    color: "white",
  });
  const textFill = new Fill({
    color: "black",
  });
  const textStroke = new Stroke({
    color: "rgba(0, 0, 0, 0.6)",
  });
  const innerCircle = new CircleStyle({
    radius: 20,
    fill: innerCircleFill,
  });

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>({
      type: types.DESTROY_STATE
    })
  }, [])


  const { isLoading: isLoadingBlockData, data: blocksByUserAndProductGroup } =
    useQuery(
      [
        "/farm/getBlocksByUserAndProductGroup?product_group_id=",
        selectedRiskVarient,
      ],
      async () => {
        return await axios.get(
          `/farm/getBlocksByUserAndProductGroup?product_group_id=${selectedRiskVarient}`
        );
      },
      {
        networkMode: "offlineFirst",
        // refetchOnMount: false,
        refetchOnWindowFocus: false,
        onError: (err: any) => {
          console.log("react-query-result", err.response?.data || err);
        },
      }
    );

  const hideToolTip = () => {
    if (popupRef.current) popupRef.current.style.display = "none";
  };

  const tooltipInfo = (event: MapBrowserEvent<any>, feature: Feature) => {
    const properties = feature.getProperties();
    let point: any = feature.getGeometry();
    const coordinate = point.getCoordinates();
    const pixel = event.map?.getPixelFromCoordinate(coordinate)!!;

    if (pixel[0] + TOOLTIP_WIDHT > window.innerWidth) {
      pixel[0] = pixel[0] - TOOLTIP_WIDHT;
    }
    if (pixel[1] + TOOLTIP_HEIGHT > window.innerHeight) {
      pixel[1] = pixel[1] - TOOLTIP_HEIGHT;
    }
    if (popupRef.current) {
      popupRef.current.onclick = () => {
        console.log("_______CLICKED_TOOLTIP_______________", properties.blockId)
        navigate(`/report/${properties.blockId}`);
      }
      popupRef.current.style.display = "block";
      popupRef.current.style.left = `${Math.floor(pixel[0])}px`;
      popupRef.current.style.top = `${Math.floor(pixel[1])}px`;
      if (popupNameRef.current) popupNameRef.current.innerHTML = `${properties.blockName}`;
      if (popupLocationRef.current) popupLocationRef.current.innerHTML = `${properties.complex}, ${properties.division}, ${properties.estate}, ${properties.region}, ${properties.psm}`;
      if (properties.crop_type && properties.area)
        if (popupInfoRef.current) popupInfoRef.current.innerHTML = `${properties.crop_type.toUpperCase()} • ${parseFloat(
          properties.area
        ).toFixed(2)}ha`;
    }
  };




  useEffect(() => {
    if (map) {
      unByKey(mapContext.eventListenerKeys)
      map?.getView().setCenter(fromLonLat(HOME_LONG_LAT_CENTER));
      map?.getView().setMinZoom(HOME_ZOOM);
      const onMapClick = map?.on("click", (event) => {
        console.log("event.pixel ->", event.pixel);
        event.map.getAllLayers().forEach((layer) => {
          if (layer &&
            layer.get("name") !== undefined &&
            layer.get("name") === "cluster") {
            const clusters = layer;
            clusters.getFeatures(event.pixel).then((features) => {
              if (features.length > 0) {
                const clusterMembers: Feature<Geometry>[] = features[0].get("features");
                if (clusterMembers.length > 1 ||
                  event.map?.getView().getZoom()!! < SHOW_GEOJSON_ZOOM_THRESHOLD) {
                  mapContext.fitExtentAroundFeatures(event.map, clusterMembers);
                }
                if (clusterMembers.length === 1) {
                  const properties = clusterMembers[0].getProperties();
                  navigate(`/report/${properties.blockId}`);
                  event.map.getTargetElement().style.cursor = '';


                }
              }
            });
          }
        });
      });
      const onMapPointMove = map?.on("pointermove", (event: MapBrowserEvent<any>): void => {
        event.map.getAllLayers().forEach((layer) => {
          if (layer &&
            layer.get("name") !== undefined &&
            layer.get("name") === "cluster") {
            const clusters = layer;
            clusters.getFeatures(event.pixel).then((features) => {
              if (features.length > 0) {
                event.map.getTargetElement().style.cursor = 'pointer';
                // const farms = features[0].get("features");
                // if (farms.length === 1) {

                // tooltipInfo(event, farms[0]);

                // }
              } else {
                if (popupRef !== undefined)
                  hideToolTip();
                event.map.getTargetElement().style.cursor = '';
              }
            });
          }
        });
      });
      mapContext.setEventListenerKeys([onMapClick, onMapPointMove])
    }
  }, [map]);


  useEffect(() => {
    if (map && blocksByUserAndProductGroup && blocksByUserAndProductGroup.data) {
      let refreshFlag = true;
      console.log();
      map.getAllLayers().forEach((layer: any) => {
        if (
          layer &&
          layer.get("name") !== undefined &&
          layer.get("name") === "cluster"
        ) {
          const clusterFeaturesLength = layer
            ?.getSource()
            .getSource()
            ?.getFeatures()?.length;
          if (
            blocksByUserAndProductGroup.data.features.length ===
            clusterFeaturesLength
          ) {
            refreshFlag = false;
          }
        }
      });
      if (refreshFlag) {
        mapContext.removeLayers(map)
        const polygonGroup = new LayerGroup();
        polygonGroup.set("name", "polygon-layer");
        map?.getLayers().insertAt(1, polygonGroup);
        const clusterMemberStyle = (clusterMember: any) => {
          const { blockId, blockName, risk } = clusterMember.getProperties();
          const color_map: any = RISK_COLOR_MAP;
          if (!geoJsonBlockIdSet.has(blockId)) {
            geoJsonBlockIdSet.add(blockId);
            let layerExists = false;
            polygonGroup?.getLayers().forEach((layer) => {
              if (
                layer.get("name") !== undefined &&
                layer.get("name") === blockId
              ) {
                layer.setVisible(true);
                layerExists = true;
              }
            });
            if (!layerExists) {
              const polygonVectorLoader = () => {
                axios
                  .get(
                    `/farm/getBlockGeoJson?block_id=${blockId}&block_name=${blockName}`
                  )
                  .then((res) => {
                    var geoJsonFormat = new GeoJSON({
                      featureProjection: "EPSG:3857",
                    });
                    var features = geoJsonFormat.readFeatures(
                      JSON.stringify(res.data)
                    );
                    ploygonVectorSource.addFeatures(features);
                  })
                  .catch((err) => {
                    console.log(err.response.data);
                  });
              };

              const ploygonVectorSource = new VectorSource({
                attributions: "GeoJsonLayer",
                loader: polygonVectorLoader,
              });

              const blockPolygonLayer = new VectorLayer({
                source: ploygonVectorSource,
                style: new Style({
                  stroke: new Stroke({
                    color: `#fff`,
                    width: 1,
                  }),
                  fill: new Fill({
                    color: `${color_map[risk]}80`,
                  }),
                }),
              });

              blockPolygonLayer.set("name", blockId);
              blockPolygonLayer.setVisible(true);
              polygonGroup?.getLayers().insertAt(0, blockPolygonLayer);
            }
          }

          return [
            new Style({
              image: innerCircle,
            }),
            new Style({
              geometry: clusterMember.getGeometry(),
              image: createDonutChart([1], MIN_DONUT_RADIUS, [
                `${color_map[risk]}`,
              ]),
              text: new Text({
                text: clusterMember.getProperties().blockName,
                fill: textFill,
              }),
            }),
          ];
        };

        const clusterStyle = (feature: any): any => {
          const size = feature.get("features").length;
          if (
            size > 1 ||
            map?.getView().getZoom()!! < SHOW_GEOJSON_ZOOM_THRESHOLD
          ) {
            const riskData = [0, 0, 0];
            let riskMap: any;
            if (selectedRiskVarient === RISK_SELECTION.HEALTH || selectedRiskVarient === RISK_SELECTION.LEC) {
              riskMap = HEALTH_RISK_INDEX_MAP;
            } else {
              riskMap = NUTRIENT_RISK_INDEX_MAP;
            }
            feature.get("features").forEach((element: Feature<Geometry>) => {
              const { blockId, risk } = element.getProperties();
              riskData[riskMap[risk]] += 1;
              geoJsonBlockIdSet.delete(blockId);
              polygonGroup?.getLayers().forEach((layer) => {
                if (
                  layer.get("name") !== undefined &&
                  layer.get("name") === blockId
                ) {
                  layer.setVisible(false);
                }
              });
            });
            return [
              new Style({
                image: innerCircle,
              }),
              new Style({
                image: createDonutChart(
                  riskData,
                  Math.min(
                    MIN_DONUT_RADIUS + 8 * Math.sqrt(size / Math.PI),
                    MAX_DONUT_RADIUS
                  ),
                  [
                    `${RISK_COLOR_MAP.LOW}`,
                    `${RISK_COLOR_MAP.MEDIUM}`,
                    `${RISK_COLOR_MAP.HIGH}`,
                  ]
                ),
                text: new Text({
                  text: `${size}\n${BLOCK_CATEGORY_NAME}${size === 1 ? "" : "s"
                    }`,
                  fill: textFill,
                  stroke: textStroke,
                }),
              }),
            ];
          } else {
            const originalFeature = feature.get("features")[0];
            return clusterMemberStyle(originalFeature);
          }
        };
        setSearchFeatures(blocksByUserAndProductGroup.data.features);

        const vectorLoader = () => {
          let geoJsonFormat = new GeoJSON({ featureProjection: "EPSG:3857" });
          let clusterFeatures = geoJsonFormat.readFeatures(
            JSON.stringify(blocksByUserAndProductGroup.data)
          );
          mapContext.fitExtentAroundFeatures(map, clusterFeatures);
          vectorSource.addFeatures(clusterFeatures);
        };
        const vectorSource = new VectorSource({
          format: new GeoJson(),
          loader: vectorLoader,
        });

        const clusterSource = new Cluster({
          distance: CLUSTER_DISTANCE_THRESHOLD,
          source: vectorSource,
        });

        // Layer displaying the clusters and individual features.
        const clusters = new VectorLayer({
          source: clusterSource,
          style: clusterStyle,
        });
        clusters.set("name", "cluster");

        map?.getView()?.setCenter(fromLonLat(HOME_LONG_LAT_CENTER));
        map?.getLayers().insertAt(2, clusters);
      }
    }
  }, [map, blocksByUserAndProductGroup]);

  const handleRiskSelectionClick = (selection: number) => {
    if (selectedRiskVarient !== selection) {
      if (map) {
        mapContext.removeLayers(map, []);
        map?.getView().setCenter(fromLonLat(HOME_LONG_LAT_CENTER));
        map?.getView().setMinZoom(HOME_ZOOM);
      }
      setSelectedRiskVarient(selection);
    }
  };
  const handleOnSearchChange = (_: any, feature: any) => {
    if (feature) {
      map?.getView()?.animate({
        center: fromLonLat([
          feature.geometry.coordinates[0],
          feature.geometry.coordinates[1],
        ]),
        duration: 1000,
        zoom: HOME_MAX_ZOOM,
      });
    }
  };
  return (
    <div className="Home">
      <Loading open={isLoadingBlockData} />
      <RiskLegend selectedRiskVarient={selectedRiskVarient} />
      {/* <FarmInfoTooltip
        tooltipRef={popupRef}
        nameRef={popupNameRef}
        infoRef={popupInfoRef}
        locationRef={popupLocationRef}
      /> */}
      <div className="top-bar">
        <SearchBar
          keyVal={selectedRiskVarient}
          features={searchFeatures}
          onChange={handleOnSearchChange}
        />

        <div>
          <Chip
            className="chip"
            variant="filled"
            color={
              selectedRiskVarient === RISK_SELECTION.NUTRIENT
                ? "primary"
                : "secondary"
            }
            label="Nutrient Risk"
            onClick={() => handleRiskSelectionClick(RISK_SELECTION.NUTRIENT)}
          />
          <Chip
            className="chip"
            variant="filled"
            color={
              selectedRiskVarient === RISK_SELECTION.HEALTH
                ? "primary"
                : "secondary"
            }
            label="Health Risk"
            onClick={() => handleRiskSelectionClick(RISK_SELECTION.HEALTH)}
          />
          <Chip
            className="chip"
            variant="filled"
            color={
              selectedRiskVarient === RISK_SELECTION.LEC
                ? "primary"
                : "secondary"
            }
            label="LEC"
            onClick={() => handleRiskSelectionClick(RISK_SELECTION.LEC)}
          />
        </div>
        <div style={{ visibility: "hidden", width: "200px" }}></div>
      </div>
    </div>
  );
};

export default Home;
